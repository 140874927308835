<template>
  <div class="comment-wrapper" :style="{ height: `${calWindowHeight}px` }">
    <div class="comments-title">{{ $t('評論') }}</div>
    <div v-loading="isLoading" :element-loading-text="$t('加載中...')">
      <div v-for="(item, index) in commentsData" :key="index" class="comments-container">
        <div class="comments-main">
          <div class="user-img">
            <img class="avatar-35 rounded-circle img-fluid cursor_pointer" :src="item.avatar" @click="$router.push({path: `${item.user_account}`})" />
              <!-- <template #placeholder>
                <div v-loading="true"></div>
              </template>
            </el-image> -->
          </div>
          <div class="comments-data">
            <h6 class="account_info" @click="$router.push({path: `${item.user_account}`})">{{ item.user_name }}</h6>111
            <p class="mb-0" v-html="textToTagFormat(item.post_comment)"></p>
            <div class="d-flex flex-wrap align-items-center comment-activity">
              <span class="pe-1">
                {{ item.created_time }}, {{ item.created_date }}
              </span>
              <a href="javascript:void(0);" @click="rep(index, item)">{{ $t('回覆') }}</a>
              <a v-if="user.account === item.user_account" href="javascript:void(0);" @click="del(item)">{{ $t('刪除') }}</a>
            </div>
          </div>
        </div>
        <div class="like-button">
          <i :class="`fa${
              !item.is_user_likes ? 'r' : ''
            } fa-heart me-0 text-primary`" @click="commentLike(item)"></i>
        </div>
      </div>

      <div v-show="!commentsData.length" class="no-comments">
        <div>{{ $t('尚無評論') }}</div>
      </div>

      <!-- reply -->
      <form v-if="!isShowCommnet" class="comment-text d-flex align-items-center mt-3" action="javascript:void(0);">
        <input v-model="commentMessage" type="text" class="form-control rounded" :placeholder="$t('label.enterYourComment')" />
        <div class="comment-attagement d-flex">
          <a @click="send(index, item)" href="javascript:void(0);">
            <button type="button" class="btn btn-circle-sm mb-1 me-1">
              <i class="far fa-paper-plane me-0 text-primary"></i>
            </button>
          </a>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { useConvert } from '@/utils/useConvert'
import { computed, onMounted, ref, watch } from 'vue'
import {
  delPost,
  getPostById,
  addCommentLike,
  addPostComment
} from '@/api/post'
import { useStore } from 'vuex'

export default {
  name: 'Comments',
  props: {
    windowHeight: {
      type: Number,
      default: 0
    },
    postId: {
      type: String || Number,
      default: 0
    }
  },
  setup(props) {
    const store = useStore()

    const { textToTagFormat } = useConvert()

    const calWindowHeight = computed(() => props.windowHeight)
    const currentPostId = computed(() => props.postId)
    const user = computed(() => store.state.User.user)

    const commentsData = ref([])
    const isLoading = ref(false)
    const isShowCommnet = ref(false)
    const commentMessage = ref('')

    const rep = (commentIdx, target) => {
      commentMessage.value = ''
      commentMessage.value = `${target.user_account}, `
    }
    const del = async target => {
      try {
        const res = await delPost({
          post_comment_id: target.post_comment_id
        })
        if (res.status) {
          await getCurrentPost(true)
        }
      } catch (error) {
        console.log(error)
      }
    }
    const send = async () => {
      const d = {
        post_comment: commentMessage.value,
        post_id: currentPostId.value
      }

      try {
        const res = await addPostComment(d)
        if (res.status) {
          commentMessage.value = ''
          commentsData.value.push(res.data)
        }
      } catch (error) {
        console.log(error)
      }
    }

    const getCurrentPost = async (noloading = false) => {
      if (!noloading) isLoading.value = true
      try {
        const res = await getPostById(currentPostId.value)
        if (res.status) {
          commentsData.value = [...res.data.post_comment]
          isShowCommnet.value = res.data.post_comment_show
        }
      } catch (error) {
        console.log(error)
      }

      setTimeout(() => {
        if (!noloading) isLoading.value = false
      }, 500)
    }

    watch(currentPostId, async () => {
      await getCurrentPost()
    })

    onMounted(async () => {
      await getCurrentPost()
    })

    const commentLike = async target => {
      try {
        const res = await addCommentLike({
          post_comment_id: target.post_comment_id
        })
        if (res.status) {
          await getCurrentPost(true)
        }
      } catch (error) {
        console.log(error)
      }
    }

    return {
      textToTagFormat,
      commentsData,
      rep,
      del,
      send,
      calWindowHeight,
      currentPostId,
      isLoading,
      commentLike,
      isShowCommnet,
      commentMessage,
      user
    }
  }
}
</script>

<style lang="scss" scoped>
.comments-title {
  color: #50b5ff;
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  padding-bottom: 8px;
  border-bottom: 1px dashed #50b5ff;
  margin-bottom: 24px;
}
.comment-wrapper {
  // width: 30%;
  width: 392px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 25px;
  overflow-y: auto;
}
.comments-container {
  margin-bottom: 0.625rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.comments-main {
  display: flex;
  align-items: center;
}
.comments-data {
  margin-left: 1.25rem;
}

.no-comments {
  min-height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.like-button {
  cursor: pointer;
}

form.comment-text input {
  padding-right: 36px !important;
}
</style>

<template>
  <div class="videos" :style="{
      height: `${dynamicWindowHeight}px`
    }">
    <div v-if="video_url?.type === 'video'" class="vedio-item-container" :style="{
        height: `${dynamicWindowHeight}px`
      }">
      <Vue3VideoPlay ref="videoPlayer" type="video/mp4" :width="`${windowWidth}px`" :height="`${dynamicWindowHeight}px`" :color="'#57d518'" :src="video_url?.thumbnail?.urls[0] || ''"
        :poster="video_url?.coverletter || ''" autoPlay loop :volume="false" :playsinline="false" :preload="'meta'" :control="false" :controlBtns="[]" :speed="false" muted @play="play" @pause="stop" />
      <!-- <div id="video-background" @click="playAndStop">
        <video webkit-playsinline="true" playsinline="true" :preload="'none'" loop :width="`${windowWidth}`" :height="`${dynamicWindowHeight}`" :poster="video_url?.coverletter || ''" ref="videoPlayer"
          @play="play" @pause="stop">
          <source :src="video_url?.url || ''" type="video/mp4" >
          <source :src="video_url?.url || ''" type="video/quicktime" >
          <source :src="video_url?.url || ''" type="video/ogg" >
          <source :src="video_url?.url || ''" type="video/webm" >
          Your browser does not support the video tag.
        </video>
      </div> -->
      <div class="mask-image">
        <img :src="video_url.coverletter" alt="" />
      </div>
    </div>

    <div v-else-if="video_url?.type === 'photo'" :style="{
        height: `${dynamicWindowHeight}px`
      }">
      <swiper ref="videoPlayer" :autoHeight="true" :modules="modules" :pagination="pagination" :loop="true" :slides-per-view="1" :space-between="0" :autoplay="{
          delay: 50000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false
        }" @slideChange="carouselChange">
        <swiper-slide v-for="(slideContent, slideIndex) in video_url.thumbnail.urls" :key="slideIndex">
          <div class="carousel-image">
            <img :src="slideContent.media_thumb_url_webp" alt="" :class="{ rectangle: slideContent.height > slideContent.width }" />
          </div>

          <div class="mask-image">
            <img :src="slideContent.media_square_url_webp" alt="" />
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'
import { Pagination } from 'swiper'
import Vue3VideoPlay from 'vue3-video-play'
import 'swiper/modules/pagination/pagination.scss'

export default {
  name: 'Videos',
  components: {
    Swiper,
    SwiperSlide,
    Vue3VideoPlay
  },
  data() {
    return {
      h: 0,
      w: 0,
      config: {
        id: 'vs',
        url: '/xgplayer-demo.mp4'
      },
      Player: null,
      renderComponent: false,
      options: {
        width: '100%',
        height: '98%',
        color: '#57d518',
        title: '',
        src: '',
        muted: false,
        webFullScreen: false,
        speedRate: ['0.75', '1.0', '1.25', '1.5', '2.0'],
        autoPlay: false,
        loop: true,
        mirror: false,
        ligthOff: false,
        volume: 1,
        control: false,
        speed: false,
        playsinline: true,
        controlBtns: [
          'audioTrack',
          'quality',
          'speedRate',
          'volume',
          'setting',
          'pip',
          'pageFullScreen',
          'fullScreen'
        ] //显示所有按钮,
      },
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      // currentIndex: 0,
      modules: [Pagination],
      pagination: {
        clickable: true,
        renderBullet: (index, className) => `<span class="${className}"></span>`
      },
      swiperInstance: null,
      currentIndex: 0
    }
  },
  methods: {
    playAndStop() {
      if (this.$refs.videoPlayer.paused) {
        this.$refs.videoPlayer.play()
      } else {
        this.$refs.videoPlayer.pause()
      }
    },
    play() {
      if (this.$refs.videoPlayer && this.video_url.type === 'video') {
        this.$refs.videoPlayer.play()
      }

      // if (!this.$refs.videoPlayer?.player) {
      //   return
      // }
      // this.$refs.videoPlayer.player.play()
      // this.playing = true
    },
    stop() {
      if (this.$refs.videoPlayer && this.video_url.type === 'video') {
        this.$refs.videoPlayer.pause()
      }
      // if (!this.$refs.videoPlayer?.player) {
      //   return
      // }
      // this.$refs.videoPlayer.player.pause()
      // this.playing = false
    },
    onResize() {
      this.windowHeight = window.innerHeight
      this.windowWidth = window.innerWidth
    },
    carouselChange(swiperData) {
      this.swiperInstance = swiperData
      // this.currentIndex = swiperData.activeIndex
    },
    controlAutoPlay(type) {
      if (this.video_url?.type === 'photo') {
        if (this.swiperInstance && this.swiperInstance?.autoPlay) {
          type === 'stop'
            ? this.swiperInstance.autoPlay.pause()
            : this.swiperInstance.autoPlay.start()
        }
      }
    }
  },
  props: ['video_url', 'index', 'type'],
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    isAppleDevice() {
      const ua = navigator.userAgent.toLowerCase()
      return /iphone|ipad|iphone|Mac/i.test(ua)
    },
    videoOption() {
      console.log('src : ', this.video_url?.thumbnail?.urls[0])
      return {
        type: 'video/mp4',
        width: `${this.windowWidth}px`, //播放器高度
        height: `${this.windowHeight}px`, //播放器高度
        color: '#57d518', //主题色
        title: '', //视频名称
        src: this.video_url?.thumbnail?.urls[0], //视频源
        poster: this.video_url.coverletter, //封面
        muted: this.isAppleDevice ? true : false, //静音
        webFullScreen: false,
        speedRate: ['0.75', '1.0', '1.25', '1.5', '2.0'], //播放倍速
        autoPlay: true, //自动播放
        loop: true, //循环播放
        mirror: false, //镜像画面
        ligthOff: false, //关灯模式
        volume: 0, //默认音量大小
        control: false, //是否显示控制
        speed: false, //关闭进度条拖动
        playsinline: false,
        preload: 'meta'
      }
    },
    coverletterUrl() {
      if (this.video_url?.type === 'video') {
        return this.video_url.coverletter
      } else {
        return this.video_url.thumbnail.urls[this.currentIndex].media_url
      }
    },
    dynamicWindowHeight() {
      return Number(this.windowWidth) <= 575.98
        ? Number(this.windowHeight) - 60
        : Number(this.windowHeight)
    },
    test() {
      return this.$refs.videoPlayer
    }
  },
  watch: {
    'swiperInstance.realIndex': {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) this.currentIndex = newValue
      }
    }
  }
}
</script>

<style scoped>
#video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}
#video-background video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.el-carousel__item h3 {
  display: flex;
  color: #475669;
  opacity: 0.75;
  line-height: 500px;
  margin: 0;
}

.el-carousel__item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-carousel__item:nth-child(2n) {
  /* background-color: #99a9bf; */
  background-color: transparent;
}

.el-carousel__item:nth-child(2n + 1) {
  /* background-color: #d3dce6; */
  background-color: transparent;
}

.vedio-item-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

/* .carousel-image > img {
  width: 100%;
} */

::v-deep .el-carousel__indicators--horizontal {
  top: 0;
  width: 100%;
  display: flex;
}
::v-deep .el-carousel__indicators--horizontal > li {
  width: 100%;
}
::v-deep .el-carousel__button {
  width: 100%;
}

.mask-image {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
.mask-image img {
  /* 聽說加這個IOS會很卡 */
  filter: blur(60px);
  transform: translate3d(0, 0, 0);
  height: 100%;
  opacity: 0.8;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

:deep(.swiper) {
  height: 100%;
}
:deep(.swiper-slide) {
  height: 100%;
}
.carousel-image {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel-image > img {
  width: 100%;
  height: auto;
  /* height: 100%; */
}
@media (min-width: 930px) {
  .carousel-image > img.rectangle {
    width: auto;
    height: 100%;
  }
}

:deep(.swiper-pagination) {
  top: 2%;
  height: 16px;
  display: flex;
}
:deep(.swiper-pagination-bullet) {
  width: 100% !important;
  border-radius: 20px !important;
  height: 2px;
  background-color: #fff;
  opacity: 0.48;
}
:deep(.swiper-pagination-bullet-active) {
  opacity: 1;
}

:deep(.xgplayer-skin-default) {
  background: #989898ab;
}

/* .videos {
  position: relative;
} */

:deep(.xgplayer) {
  height: 100% !important;
}

/* :deep(.swiper-wrapper) {
  height: 100% !important;
}
:deep(.swiper-slide) {
  height: 100% !important;
} */
</style>

<template>
  <el-drawer v-model="isOpen" :with-header="false" size="60%" :show-close="false" direction="btt" title="Tips" :custom-class="'mobile-post-dialog'">
    <template #header>
      <div></div>
    </template>
    <div>
      <div>
        <div class="user-post-data">
          <div class="d-flex justify-content-between">
            <div class="me-3">
              <!-- <img class="avatar-60 rounded-circle" :src="postData.avatar" /> -->
              <img class="avatar-60 rounded-circle" :src="postData?.avatar">
                <!-- <template #placeholder>
                  <div v-loading="true"></div>
                </template>
              </el-image> -->
            </div>
            <div class="w-100">
              <div class="d-flex justify-content-between">
                <div class="">
                  <h5 class="mb-0 d-inline-block">{{ postData?.user_name }}</h5>

                  <div class="mb-0 text-gray float-right">
                    <router-link class="link-gray me-1" :to="`/${postData?.user_account}`">
                      {{ postData?.user_account }}
                    </router-link>

                    <el-tooltip class="item" effect="dark" :content="`${postData?.created_date} ${postData?.created_time}`" placement="bottom">
                      <span class="d-inline-block mb-0 text-primary">
                        ·
                        {{
                          formatDate(
                            `${postData?.created_date} ${postData?.created_time}`
                          )
                        }}
                      </span>
                    </el-tooltip>
                  </div>
                </div>
                <div class="card-post-toolbar d-flex justify-content-end">
                  <div class="pe-2 mb-0 d-inline-block">
                    <el-tag size="mini" effect="plain" round>{{
                      postData?.post_type_name
                    }}</el-tag>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <p :class="{
              more:
                postData && postData?.post_content.length > 200 && isEllipsis
            }">
            {{ postData?.post_content }}
          </p>
        </div>
        <div class="mt-3">
          <div v-show="postData?.post_content.length > 200">
            <a href="javascript:void(0)" v-show="isEllipsis">
              <p @click="more()">{{ $t('label.showMore') }}</p>
            </a>
            <a href="javascript:void(0)" v-show="!isEllipsis">
              <p @click="few()">{{ $t('label.showLess') }}</p>
            </a>
          </div>
        </div>
        <div v-loading="isLoading" :element-loading-text="$t('加載中...')">
          <div v-for="(item, index) in postData?.post_comment" :key="index" class="comments-container">
            <div class="comments-main">
              <div class="user-img">
                <img class="avatar-35 rounded-circle img-fluid cursor_pointer" :src="item.avatar" @click="$router.push({path: `${item.user_account}`})" />
                  <!-- <template #placeholder>
                    <div v-loading="true"></div>
                  </template>
                </el-image> -->
              </div>
              <div class="comments-data">
                <h6 class="account_info" @click="$router.push({path: `${item.user_account}`})">{{ item.user_name }}</h6>
                <p class="mb-0" v-html="textToTagFormat(item.post_comment)"></p>
                <div class="d-flex flex-wrap align-items-center comment-activity">
                  <span class="pe-1">
                    {{ item.created_time }}, {{ item.created_date }}
                  </span>
                  <a href="javascript:void(0);" @click="rep(index, item)">{{ $t('回覆') }}</a>
                  <a v-if="user.account === item.user_account" href="javascript:void(0);" @click="del(item)">{{ $t('刪除') }}</a>
                </div>
              </div>
            </div>
            <div class="like-button">
              <i :class="`fa${
                  !item.is_user_likes ? 'r' : ''
                } fa-heart me-0 text-primary`" @click="commentLike(item)"></i>
            </div>
          </div>

          <div v-show="!postData?.post_comment?.length" class="no-comments">
            <div>{{ $t('尚無評論') }}</div>
          </div>

          <!-- reply -->
          <form v-if="!postData?.post_comment_show" class="comment-text d-flex align-items-center mt-3" action="javascript:void(0);">
            <input v-model="commentMessage" type="text" class="form-control rounded" :placeholder="$t('label.enterYourComment')" />
            <div class="comment-attagement d-flex">
              <a @click="send(index, item)" href="javascript:void(0);">
                <button type="button" class="btn btn-circle-sm mb-1 me-1">
                  <i class="far fa-paper-plane me-0 text-primary"></i>
                </button>
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { useConvert } from '@/utils/useConvert'
import { useDate } from '@/utils/useDate'
import { useCheckout } from '@/utils/useCheckout'
import {
  delPost,
  getPostById,
  addCommentLike,
  addPostComment
} from '@/api/post'
import { ref, computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'MobilePost',
  props: {
    windowHeight: {
      type: Number,
      default: 0
    },
    windowWidth: {
      type: Number,
      default: 0
    },
    postId: {
      type: String || Number,
      default: 0
    }
  },
  setup(props) {
    const { textToTagFormat } = useConvert()
    const { formatDate } = useDate()
    const { openDialog } = useCheckout()
    const user = computed(() => store.state.User.user)

    const store = useStore()
    const isOpen = computed({
      get: () => store.state.Setting.isMobilePostOpen,
      set: newValue => {
        store.dispatch('Setting/changeIsMobilePostOpen', newValue)
      }
    })
    const calWindowHeight = computed(() => props.windowHeight)
    const calWindowWidth = computed(() => props.windowWidth)
    const currentPostId = computed(() => props.postId)

    const postData = ref(null)
    const isLoading = ref(false)
    const commentMessage = ref('')

    const getPostData = async (noloading = false) => {
      if (!noloading) isLoading.value = true
      try {
        if (currentPostId.value) {
          const res = await getPostById(currentPostId.value)
          if (res.status) {
            postData.value = { ...res.data }
          }
        }
      } catch (error) {
        console.log(error)
      }
      if (!noloading) isLoading.value = false
    }

    const isEllipsis = ref(true)
    const more = () => {
      isEllipsis.value = false
    }
    const few = () => {
      isEllipsis.value = true
    }

    watch(isOpen, async newValue => {
      if (newValue) {
        await getPostData()
      }
    })

    const getOption = r => {
      return {
        width: '100%',
        height: '100%',
        color: '#818181',
        muted: false,
        webFullScreen: false,
        speedRate: ['0.75', '1.0', '1.25', '1.5', '2.0'],
        autoPlay: false,
        loop: false,
        mirror: false,
        ligthOff: false,
        volume: 0.3,
        control: true,
        title: '',
        src: r.media_video_url,
        poster: r.media_url,
        preload: 'meta'
      }
    }

    const previewUser = ref({})

    const rep = (commentIdx, target) => {
      commentMessage.value = ''
      if (target.user_account[0] === '@') {
        commentMessage.value = `${target.user_account}, `
      } else {
        commentMessage.value = `@${target.user_account}, `
      }
    }
    const del = async target => {
      try {
        const res = await delPost({
          post_comment_id: target.post_comment_id
        })
        if (res.status) {
          await getPostData(true)
        }
      } catch (error) {
        console.log(error)
      }
    }
    const commentLike = async target => {
      try {
        const res = await addCommentLike({
          post_comment_id: target.post_comment_id
        })
        if (res.status) {
          await getPostData(true)
        }
      } catch (error) {
        console.log(error)
      }
    }
    const send = async () => {
      const d = {
        post_comment: commentMessage.value,
        post_id: currentPostId.value
      }

      try {
        const res = await addPostComment(d)
        if (res.status) {
          commentMessage.value = ''
          postData.value.post_comment.push(res.data)
        }
      } catch (error) {
        console.log(error)
      }
    }

    const isFullScreen = ref(false)
    watch(calWindowWidth, newValue => {
      if (newValue) {
        isFullScreen.value = newValue < 930
      }
    })

    onMounted(() => {
      isFullScreen.value = calWindowWidth.value < 930
    })

    return {
      isOpen,
      postData,
      currentPostId,
      formatDate,
      more,
      few,
      isEllipsis,
      getOption,
      openDialog,
      previewUser,
      isLoading,
      rep,
      del,
      send,
      commentLike,
      textToTagFormat,
      calWindowHeight,
      calWindowWidth,
      isFullScreen,
      commentMessage,
      user
    }
  }
}
</script>

<style lang="scss" scoped>
.more {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  box-sizing: border-box;
}

.comments-container {
  margin-bottom: 0.625rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.comments-main {
  display: flex;
  align-items: center;
}
.comments-data {
  margin-left: 1.25rem;
}

.no-comments {
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.like-button {
  cursor: pointer;
}
</style>

<template>
  <div class="row">
    <div class="tab-content" :class="{
        'swiper-with-comments': isPcCommentOpen
      }">
      <div v-show="false">{{ windowWidth }}</div>
      <div v-show="false">{{ index }}</div>
      <div class="video-list" :class="{
          'swiper-with-comments': isPcCommentOpen
        }" v-cloak>
        <Swiper id="recommend-swiper" scrollbar ref="mySwiper" :mousewheel="true" :options="swiperOptions" :observe="true">
          <SwiperSlide :class="{
              'swiper-with-comments': isPcCommentOpen
            }" v-for="(item, index) in list" :key="index">
            <div class="swiper-item-wrapper" :class="{
                'pc-with-comment': isPcCommentOpen
              }">
              <videos ref="videos" :video_url="item" :index="index" />
              <right-bar :item="item" :windowWidth="windowWidth" />
              <video-info :item="item" :index="index" />
            </div>
            <!-- <Comments v-if="isPcCommentOpen" /> -->
          </SwiperSlide>
        </Swiper>
      </div>
      <Comments v-if="isPcCommentOpen" :windowHeight="windowHeight" :postId="list[index]?.id" />
    </div>

    <MobilePost :windowHeight="windowHeight" :windowWidth="windowWidth" :postId="list[index]?.id"></MobilePost>
    <TipPay></TipPay>
  </div>
</template>

<script>
import Swiper from '../../../../components/custom/slider/Swiper'
import SwiperSlide from '../../../../components/custom/slider/SwiperSlide'
import Videos from '../../../Apps/Social/Components/Videos'
import VideoInfo from '../../../Apps/Social/Components/VideoInfo'
import RightBar from '../../../Apps/Social/Components/RightBar'
import { getPost } from '@/api/post'
import { commomData } from '@/utils/commonVmodel'
import Comments from './Comments.vue'
import { mapActions } from 'vuex'
import MobilePost from './MobilePost.vue'
import TipPay from '@/components/custom/dialog/TipPay'

export default {
  name: 'VideoList',
  components: {
    RightBar,
    Swiper,
    SwiperSlide,
    Videos,
    VideoInfo,
    Comments,
    MobilePost,
    TipPay
  },
  data() {
    return {
      primaryKey: 'created_at',
      commomData: commomData(),
      w: 0,
      r: false,
      index: 0,
      options: {
        centeredSlides: false,
        loop: false,
        slidesPerView: 1,
        spaceBetween: 15,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        // And if we need scrollbar
        scrollbar: {
          el: '.swiper-scrollbar'
        }
      },
      swiperOptions: {
        // snapOnRelease: true,
        direction: 'vertical',
        grabCursor: true, //鼠标覆盖Swiper时指针会变成手掌形状
        setWrapperSize: true, //会在Wrapper上添加等于slides相加的宽或高，在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        autoHeight: true, //设置为true时，wrapper和container会随着当前slide的高度而发生变化。
        slidesPerView: 1, //能够同时显示的slides数量(carousel模式)。
        mousewheel: true, //标滚轮控制Swiper切换
        mousewhellControl: true,
        height: window.innerHeight,
        resistanceRatio: 0, //抵抗率。边缘抵抗力的大小比例。值越小抵抗越大越难将slide拖离边缘，0时完全无法拖离。
        observer: true,
        observeParents: true,
        edgeSwipeThreshold: 0,
        edgeSwipeDetection: true,
        preventClicksPropagation: true,
        preventInteractionOnTransition: true,
        on: {
          tap: () => {
            // this.playAction(this.index)
          },
          slideNextTransitionStart: () => {
            console.log('slideNextTransitionStart')
            this.index += 1
            this.nextVideo(this.index)
          },
          slidePrevTransitionEnd: () => {
            if (this.index > 0) {
              this.index -= 1
              this.preVideo(this.index)
            }
          }
        }
      },
      videoList: [
        {
          id: '1',
          volume: 0, // 初始音量
          url:
            'https://cdn.jsdelivr.net/gh/xdlumia/files/video-play/IronMan.mp4',
          type: 'mp4',
          autoplay: true,
          thumbnail: {
            pic_num: 44,
            width: 160,
            height: 90,
            col: 10,
            row: 10,
            urls: [
              '//lf9-cdn-tos.bytecdntp.com/cdn/expire-1-M/byted-player-videos/1.0.0/xgplayer-demo-thumbnail.jpg'
            ]
          },
          loop: true,
          height: window.innerHeight,
          width: this.w,
          whitelist: [''],
          closeVideoDblclick: true,
          controls: false
        },
        {
          id: '2',
          volume: 0, // 初始音量
          url:
            'https://static.smartisanos.cn/common/video/smartisan-tnt-jianguo.mp4',
          type: 'mp4',
          autoplay: true,
          thumbnail: {
            pic_num: 44,
            col: 10,
            row: 10,
            urls: [
              '//lf9-cdn-tos.bytecdntp.com/cdn/expire-1-M/byted-player-videos/1.0.0/xgplayer-demo-thumbnail.jpg'
            ]
          },
          loop: true,
          height: window.innerHeight,
          width: this.w,
          whitelist: ['']
        },
        {
          id: '3',
          volume: 0, // 初始音量
          url:
            'https://cdn.jsdelivr.net/gh/xdlumia/files/video-play/IronMan.mp4',
          type: 'mp4',
          autoplay: true,
          thumbnail: {
            pic_num: 44,
            width: 160,
            height: 90,
            col: 10,
            row: 10,
            urls: [
              '//lf9-cdn-tos.bytecdntp.com/cdn/expire-1-M/byted-player-videos/1.0.0/xgplayer-demo-thumbnail.jpg'
            ]
          },
          loop: true,

          height: window.innerHeight,
          width: this.w,
          whitelist: ['']
        },
        {
          id: '4',
          volume: 0, // 初始音量
          url:
            'http://video.izuiyou.com/zyvd/94/56/378b-5962-11ea-abee-00163e143aa6',
          type: 'mp4',
          autoplay: true,
          loop: true,

          height: window.innerHeight,
          width: this.w,
          whitelist: ['']
        },
        {
          id: '5',
          volume: 0, // 初始音量
          url:
            '//lf9-cdn-tos.bytecdntp.com/cdn/expire-1-M/byted-player-videos/1.0.0/xgplayer-demo-thumbnail.jpg',
          type: 'photo',
          autoplay: true,
          loop: true,
          height: window.innerHeight,
          width: this.w,
          whitelist: [''],
          media: [
            {
              url:
                '//lf9-cdn-tos.bytecdntp.com/cdn/expire-1-M/byted-player-videos/1.0.0/xgplayer-demo-thumbnail.jpg'
            },
            {
              url:
                '//lf9-cdn-tos.bytecdntp.com/cdn/expire-1-M/byted-player-videos/1.0.0/xgplayer-demo-thumbnail.jpg'
            }
          ]
        },
        {
          id: '6',
          volume: 0, // 初始音量
          url:
            'http://video.izuiyou.com/zyvd/94/56/378b-5962-11ea-abee-00163e143aa6',
          type: 'mp4',
          autoplay: true,
          loop: true,

          height: window.innerHeight,
          width: this.w,
          whitelist: ['']
        }
      ],
      list: [],
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    }
  },
  computed: {
    isAppleDevice() {
      const ua = navigator.userAgent.toLowerCase()
      return /iphone|ipad|iphone|Mac/i.test(ua)
    },
    isPcCommentOpen() {
      return this.$store.state.Setting.isPcCommentOpen
    },
    isMobilePostOpen() {
      return this.$store.state.Setting.isMobilePostOpen
    }
  },
  watch: {
    windowWidth: {
      immediate: true,
      handler(newValue) {
        if (newValue && newValue < 930) {
          this.changeIsPcCommentOpen(false)
        }
      }
    }
  },
  mounted() {
    document.body.style.overflow = 'hidden'
    this.$nextTick(() => {
      this.getRows()
      window.addEventListener('resize', this.onResize)
    })
    setTimeout(() => {
      this.w = document.getElementById('vue-swiper-id').offsetWidth
    }, 200)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize)
  },
  unmounted() {
    document.body.style.overflow = 'auto'
  },
  methods: {
    ...mapActions('Setting', ['changeIsPcCommentOpen']),
    convert(arr) {
      const vm = this
      return arr.map(item => {
        return {
          postCommentCount: item.post_comment?.length,
          id: item.post_id,
          post_id: item.post_id,
          is_user_follow: item.is_user_follow,
          is_user_likes: item.is_user_likes,
          volume: 0,
          url:
            Array.isArray(item.post_media) && item.post_media[0]
              ? item.post_media[0].media_video_url
              : '',
          coverletter: item.post_media[0]?.media_url,
          type: item.post_media_type,
          autoplay: true,
          thumbnail: {
            pic_num: 44,
            width: 160,
            height: 90,
            col: 10,
            row: 10,
            urls:
              item.post_media_type === 'video'
                ? Array.isArray(item.post_media) && item.post_media[0]
                  ? [item.post_media[0].media_video_url]
                  : []
                : item.post_media
          },
          loop: true,
          height: window.innerHeight,
          width: this.w,
          whitelist: [''],
          closeVideoDblclick: true,
          controls: false,
          user_account: item.user_account,
          user_name: item.user_name,
          post_content: item.post_content,
          avatar: item.avatar,
          playsinline: vm.isAppleDevice,
          muted: vm.isAppleDevice,
          totalPostLikes: item.total_post_likes,
          totalFllowComment: item.total_fllow_comment,
          totalPostComment: item.total_post_comment,
          'x5-video-player-type': 'h5',
          'x5-video-orientation': 'portraint'
        }
      })
    },
    playAction() {
      // this.$refs.videos[this.index].playOrStop()
    },

    nextVideo() {
      /* eslint-disable */
      // debugger
      if (this.$refs.videos[this.index - 1] === undefined) {
        return
      }
      setTimeout(() => {
        console.log('nextVideo')
        this.$refs.videos[this.index - 1].stop()
        this.$refs.videos[this.index].play()
        this.$refs.videos[this.index - 1].controlAutoPlay('stop')
        this.$refs.videos[this.index].controlAutoPlay('play')
      }, 100)
      this.getRows()
    },

    preVideo() {
      if (this.$refs.videos[this.index - 1] === undefined) {
        return
      }
      setTimeout(() => {
        console.log('preVideo')
        this.$refs.videos[this.index + 1].stop()
        this.$refs.videos[this.index].play()
        this.$refs.videos[this.index + 1].controlAutoPlay('stop')
        this.$refs.videos[this.index].controlAutoPlay('play')
      }, 100)
    },
    async getRows() {
      if (this.commomData.noResult) {
        return false
      }
      this.commomData.listLoader = true
      this.commomData.condition.order_by = this.primaryKey
      this.commomData.condition.post_type = 2
      this.commomData.condition.page_size = 3
      await getPost(this.commomData.condition)
        .then(res => {
          if (this.commomData.condition.page === 1) {
            this.list = this.convert(res.data)
            this.commomData.condition.page = 2
            this.commomData.listLoader = false
            if (res.total === 0) {
              this.commomData.noResult = true
            }
          } else if (res.total > this.list.length) {
            this.list.push(...this.convert(res.data))
            this.commomData.condition.page = this.commomData.condition.page + 1
            this.commomData.listLoader = false
          } else if (res.data.length === 0) {
            this.commomData.noResult = false
            this.commomData.listLoader = false
          } else {
            this.commomData.noResult = true
          }
        })
        .catch(err => {
          console.log(err)
          // this.$swal.mixin().fire({
          //   icon: 'error',
          //   title: 'err.response.data.message'
          // })
        })
    },
    onResize() {
      this.windowHeight = window.innerHeight
      this.windowWidth = window.innerWidth
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-content {
  &.swiper-with-comments {
    display: flex;
  }
}
.video-list {
  &.swiper-with-comments {
    width: calc(100% - 392px);
  }
}

@media (max-width: 767.98px) {
  .tab-content {
    padding-right: 0;
    padding-left: 0;
  }
}

:deep(.swiper-slide.swiper-with-comments) {
  // display: flex;
  // overflow-y: auto;
  width: calc(100%);
}

.swiper-item-wrapper {
  position: relative;

  &.pc-with-comment {
    // width: 75%;
    // width: calc(100%);
  }
}
</style>

<template>
  <!-- {{user}} -->
  <!-- <div class="col-lg-12 col-md-9">
    <video-list />
  </div> -->
  <div class="video-wrapper">
    <video-list />
  </div>

  <!-- <div class="col-sm-12 text-center">
      <img
        src="../../../assets/images/page-img/page-load-loader.gif"
        alt="loader"
        style="height: 100px"
      />
    </div> -->
</template>
<script>
import VideoList from './Components/VideoList'
import { mapGetters } from 'vuex'
// import { socialvue } from '../../../config/pluginInit'
export default {
  name: 'VideoApp',
  components: {
    VideoList
  },
  computed: {
    ...mapGetters({
      user: 'User/userState',
      token: 'User/tokenState',
      isPlan: 'User/isPlanState',
      planDate: 'User/planDateState'
    })
  },
  mounted() {
    // socialvue.index()
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.video-wrapper {
  width: 83.3333333333%;
}

@media (max-width: 767.98px) {
  .video-wrapper {
    width: 100%;
  }
}
</style>

import { ref, getCurrentInstance } from 'vue'
import { checkout } from '@/api/myPay'
import { useStore } from 'vuex'
import { getSettings } from '@/api/settings'
import { useConvert } from '@/utils/useConvert'
import { socialvue } from '@/config/pluginInit' // 網站初始化的特效
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

export function useCheckout () {
  const { t } = useI18n({ useScope: 'global' })
  const { proxy } = getCurrentInstance()
  const { payIdToString } = useConvert()

  const store = useStore()
  const $toast = proxy.$toast
  const route = useRoute()

  const onLoading = ref(false)
  const action = ref('')
  const allPrograms = ref({})
  const createFormData = () => ({
    check_type: 'ewallet',
    id: 67,
    type: 'program：方案，promotions：促銷活動，subscription_bundle：訂閱綑綁',
    number: 1
  })
  const checkoutForm = ref(createFormData())
  // 當前檢視的用戶 previewUser
  // 顯示多方案 或者 單一方案 options
  const openDialog = async (
    item,
    type,
    v,
    text,
    price,
    previewUser,
    options
  ) => {
    // //console.log('openDialog', item, type, v, text, price, previewUser, options)
    socialvue.loaderReInit()
    if (Object.keys(previewUser).length === 0) {
      previewUser = item
    }
    store.dispatch('User/dialogItemAction', previewUser)
    //當選擇付款是 =>  訂閱以查看用戶的貼子時
    if (options === 'multiple') {
      try {
        allPrograms.value = await getSettings({
          user_account: item.user_account
        })
        if (
          !allPrograms.value.program.length &&
          !allPrograms.value.promotions.length &&
          !allPrograms.value.subscription_bundle.length
        ) {
          $toast.show('沒有任何訂閱方案！')
          return
        }
      } catch (error) {
        //console.log(error)
      }
    }
    // //console.log(previewUser)

    const allInfo = {
      item,
      type,
      v,
      text,
      price,
      previewUser,
      options,
      ...allPrograms.value
    }

    store.dispatch('User/dialogPayTitleAction', allInfo)
    if (type === 'post_untie') {
      store.dispatch('User/dialogPayUnlockAction', true)
    }
    else {
      store.dispatch('User/dialogPayAction', true)
    }
  }

  const openTipDialog = item => {
    //console.log(item)
    store.dispatch('User/dialogPostItemAction', item)
    store.dispatch('User/dialogTipPayAction', true)
  }

  // "type" 0 所有類型 ,訂單類型,1:購買貼文,2:贊助小費,3:私訊,4:訂閱方案,5:促銷活動,6:訂閱捆綁
  // "check_type": "tappay,ewallet", 付款方式
  // id 付款編號
  // number ???
  const orderCheckout = (check_type, id, type, number = 1, money = 0, msg = '') => {
    //console.log('orderCheckout', check_type, id, type, number, money)
    onLoading.value = true
    //console.log('orderCheckout', store)
    const dialogItemState = store.getters['User/dialogItemState']

    const d = {
      check_type: check_type,
      id: id,
      type: type === 'post_untie' ? 'post_untie' : payIdToString(type),
      number: number,
      redirect_url:
        type === 4
          ? `${location.href}${dialogItemState.user_account}`
          : location.href, // 是訂閱，完成以後轉向創作者首頁
      money: money,
      msg
    }
    //console.log(d)
    checkout(d)
      .then(res => {
        //console.log(res)
        if (res.code == 200) {
          location.href = res.data.url
        }
        if (res.code == 250) {
          $toast.show('完成付款')
          onLoading.value = false
          setTimeout(() => {
            if (type === 'post_untie') {
              if (route.name === 'social.singlepost') {
                location.reload()
              } else {
                setTimeout(() => {
                  location.href = `/post/${id}`
                }, 1000)
              }
            } else {
              location.reload();
            }
          }, 2000)
        }
        action.value = res.action
      })
      .catch(err => {
        $toast.show(err.response.data.message)
        //console.log(err)
        onLoading.value = false
      })
  }

  const PayOptions = [
    {
      value: 'ewallet',
      label: t('label.creditCardBindingReceiveWithin60Seconds')
    },
    {
      value: 'e_collection',
      label: t('label.aTMTransferDepositWithin5Minutes')
    },
    {
      value: 'cstorecode',
      label: t('label.supermarketCodeReceivedWithin1Hour')
    }
  ]

  const PayOptionsWithoutEwallet = [
    {
      value: 'e_collection',
      label: t('label.aTMTransferDepositWithin5Minutes')
    },
    {
      value: 'cstorecode',
      label: t('label.supermarketCodeReceivedWithin1Hour')
    }
  ]

  return {
    PayOptions,
    PayOptionsWithoutEwallet,
    orderCheckout,
    checkoutForm,
    action,
    onLoading,
    openDialog,
    openTipDialog
  }
}
export default useCheckout

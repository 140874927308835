import request from '../utils/request'

export function createAgent(data) {
  return request({
    url: `/myPay/createAgent`,
    method: 'post',
    data
  })
}

export function checkout(data) {
  return request({
    url: `/myPay/checkout`,
    method: 'post',
    data
  })
}

<template>
  <div class="right-bar">
    <div class="item">
      <div class="avatar-border">
        <img @click="toLink(`/${item.user_account}`)" class="avatar" :src="item.avatar" alt="" />
        <div @click="toLink(`/${item.user_account}`)" class="follow">+</div>
      </div>
    </div>
    <!-- 愛心 -->
    <div class="item">
      <div class="item-wrapper" @click="postLike(item)">
        <div class="comment-icon">
          <!-- <i class="far fa-heart me-0 "></i> -->
          <i :class="`${!item.is_user_likes ? 'far fa-heart me-0' : 'fa fa-heart me-0 text-primary'} `"></i>
        </div>
        <span class="comment-count">{{ item.totalPostLikes }}</span>
      </div>
    </div>
    <!-- 評論 -->
    <div class="item">
      <div class="item-wrapper" @click="togglePcComments">
        <div class="comment-icon">
          <i class="far fa-comment me-0 "></i>
        </div>
        <span class="comment-count">{{ item.totalPostComment }}</span>
      </div>
    </div>
    <!-- 追蹤 -->
    <div class="item">
      <div class="item-wrapper" @click="postFollow(item)">
        <div class="comment-icon">
          <i :class="`${!item.is_user_follow ? 'far fa-bookmark me-0' : 'fa fa-bookmark me-0 text-primary'} `"></i>
        </div>
        <span class="comment-count">{{ item.totalFllowComment }}</span>
      </div>
    </div>
    <div class="item">
      <div class="item-wrapper" @click="openTipDialog(item)">
        <div class="comment-icon">
          <i class="fas fa-hand-holding-usd fa-fw me-0 "></i>
        </div>
      </div>
    </div>
    <!-- <div class="item">
      <div class="like-icon">❤</div>
      <span class="like-count">19.7w</span>
    </div>
    <div class="item">
      <div class="comment-icon">💬</div>
      <span class="comment-count">2.7w</span>
    </div>
    <div class="item">
      <div class="forward-icon">💭</div>
      <span class="forward-count">196</span>
    </div>
    <div class="item">
      <div class="music-border">
        <img :src="item.avatar" alt="" />
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { addPostLike, addFollow } from '@/api/post'
import { useCheckout } from '@/utils/useCheckout'

export default {
  name: 'RightBar',
  // props: ['item', 'windowWidth'],
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    windowWidth: {
      type: Number || String,
      default: 0
    }
  },
  setup() {
    const { openTipDialog } = useCheckout()
    return {
      openTipDialog
    }
  },
  computed: {
    isPcCommentOpen() {
      return this.$store.state.Setting.isPcCommentOpen
    },
    isMobilePostOpen() {
      return this.$store.state.Setting.isMobilePostOpen
    },
    calWindowWidth() {
      return this.windowWidth
    }
  },
  methods: {
    ...mapActions('Setting', [
      'changeIsPcCommentOpen',
      'changeIsMobilePostOpen'
    ]),
    postLike(item) {
      console.log(item)
      addPostLike(item)
        .then(res => {
          console.log(res)
          item.is_user_likes = !item.is_user_likes
          if (item.is_user_likes) {
            item.totalPostLikes = item.totalPostLikes + 1
          } else {
            item.totalPostLikes = item.totalPostLikes - 1
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    postFollow(item) {
      addFollow({
        post_id: item.post_id
      })
        .then(res => {
          console.log(res)
          item.is_user_follow = !item.is_user_follow
          if (item.is_user_follow) {
            item.totalFllowComment = item.totalFllowComment + 1
          } else {
            item.totalFllowComment = item.totalFllowComment - 1
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    toLink(val) {
      this.$router.push({ path: val })
    },
    togglePcComments() {
      if (this.calWindowWidth >= 930) {
        this.changeIsPcCommentOpen(!this.isPcCommentOpen)
      } else {
        this.changeIsMobilePostOpen(!this.isMobilePostOpen)
      }
    }
  }
}
</script>

<style scoped>
.right-bar {
  position: absolute;
  right: 10px;
  bottom: 1rem;
  width: 55px;
  z-index: 999;
}
.right-bar .item .avatar-border {
  position: relative;
  /* width: 50px;
  height: 50px; */
  border: 2px solid #fff;
  border-radius: 50%;
  z-index: 10;
}
.right-bar .item .avatar {
  width: 50px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.right-bar .item .avatar-border .follow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  line-height: 24px;
  text-align: center;
  font-size: 24px;
  color: #fff;
  background-color: red;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: 1;
}

.right-bar .item {
  text-align: center;
  padding: 8px 0;
  cursor: pointer;
}

.item-wrapper {
  position: relative;
}

.right-bar .item .like-icon,
.right-bar .item .comment-icon,
.right-bar .item .forward-icon {
  color: #fff;
  font-size: 28px;
  text-shadow: 1px 1px 1px #ccc;
}

.right-bar .item .like-count,
.right-bar .item .comment-count,
.right-bar .item .forward-count {
  color: #fff;
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
}

.right-bar .item .music-border {
  width: 60px;
  height: 60px;
  background-color: #000;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: round 6s 0s linear infinite;
}
.right-bar .item .music-border img {
  width: 40px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
@keyframes round {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>

<template>
  <div :class="isMore ? 'post-container' : 'post-container show-more-more'">
    <div @click="toLink(`/${item.user_account}`)" class="post-auther">
      {{ item.user_name }}
    </div>

    <div :class="isMore ? 'poster' : 'poster-more'" ref="poster">
      {{ item.post_content }}
    </div>
    <div v-if="item.post_content.length > 60">
      <span v-if="isMore" class="show-all" @click="show('more')">{{ $t('label.showMore') }}</span>
      <span v-else class="show-all" @click="show('few')">{{ $t('label.showLess') }}</span>
    </div>
  </div>

</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'VideoInfo',
  props: ['item'],
  data() {
    return {
      isMore: true
    }
  },
  computed: {
    isPcCommentOpen() {
      return this.$store.state.Setting.isPcCommentOpen
    },
    isMobilePostOpen() {
      return this.$store.state.Setting.isMobilePostOpen
    }
  },
  methods: {
    ...mapActions('Setting', [
      'changeIsPcCommentOpen',
      'changeIsMobilePostOpen'
    ]),
    toLink(val) {
      this.$router.push({ path: val })
    },
    togglePcComments() {
      if (this.calWindowWidth >= 930) {
        this.changeIsPcCommentOpen(!this.isPcCommentOpen)
      } else {
        this.changeIsMobilePostOpen(!this.isMobilePostOpen)
      }
    },
    show(v) {
      if (v === 'more') {
        this.isMore = false
      } else {
        this.isMore = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.video-info {
  width: 100%;
  position: absolute;
  left: 5px;
  z-index: 99;
  bottom: 40px;
  padding: 0 10px;
  overflow: hidden;
}
.video-love {
  width: 100%;
  position: absolute;
  left: 5px;
  z-index: 100;
  bottom: 30px;
  padding: 0 10px;
  overflow: hidden;
}
.video-info > div {
  color: #fff;
  margin: 5px 0;
  letter-spacing: 1px;
  width: 20%;
}
// .video-love {
//   color: #fff;
//   margin: 5px 0;
//   letter-spacing: 1px;
// }
// .video-love > ul {
//   padding-left: 0 !important;
//   color: #fff;
//   font-size: 20px;
//   font-weight: bold;
//   letter-spacing: 1px;
//   text-align-last: center;
// }
// .video-love > ul > li {
//   display: inline;
//   padding: 0.5rem;
//   background-color: #fefefe1c;
//   border-radius: 0.3rem;
//   margin: 0.5rem;
// }

.video-love {
  color: #fff;
  margin: 5px 0;
  letter-spacing: 1px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.video-love-item {
  background-color: #fefefe1c;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 0.3rem;
  margin: 0.5rem;
  font-size: 20px;
  font-weight: bold;
  etter-spacing: 1px;
  text-align-last: center;
}

.video-info .nickname {
  font-weight: 700;
}
.video-info .music {
  height: 35px;
  display: flex;
  justify-items: center;
  line-height: 35px;
}
.video-info .music div {
  width: 70%;
  line-height: 35px;
  display: inline-block;
  overflow: hidden;
  margin-left: 5px;
}
.video-info .music .music-info {
  display: inline-block;
  white-space: nowrap;
  animation: 5s musicInfoLoop linear infinite normal;
}
@keyframes musicInfoLoop {
  from {
    transform: translateX(70%);
  }
  to {
    transform: translateX(-100%);
  }
}

@media (max-width: 540px) {
  .video-info {
    bottom: 64px;
  }
  .video-love {
    bottom: 48px;
  }
}

// new post context
.post-container {
  color: #fff;
  letter-spacing: 1px;
  width: 100%;
  position: absolute;
  left: 5px;
  z-index: 10;
  padding: 8px 64px 12px 12px;
  overflow: hidden;
  bottom: 0;
}
.show-more-more {
  background-image: linear-gradient(to top, #100e0e54, #6260600d);
}
.post-auther {
  font-weight: 700;
}
.poster {
  font-size: 14px;
  line-height: 1.2;
  max-height: 105px;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.poster-more {
  font-size: 14px;
  line-height: 1.2;
  // max-height: 205px;
  // text-overflow: ellipsis;
  width: 100%;
  overflow: auto; /* 添加垂直滚动条 */
  max-height: 250px; /* 根据需要设置适当的高度 */
  // display: -webkit-box;
  // -webkit-line-clamp: 3;
  // -webkit-box-orient: vertical;
  word-wrap: break-word;
  position: relative;
  z-index: 11;
}
/* 定义滚动条的样式 */
::-webkit-scrollbar {
  width: 0px; /* 滚动条的宽度 */
}

/* 定义滚动条滑块的样式 */
::-webkit-scrollbar-thumb {
  background-color: #888; /* 滑块的背景颜色 */
  border-radius: 5px; /* 滑块的圆角半径 */
}

/* 定义滚动条轨道的样式 */
::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* 轨道的背景颜色 */
}
.show-all {
  margin-top: 4px;
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  cursor: pointer;
  font-weight: 900;
}

@media (min-width: 767.98px) {
  .post-container {
    padding: 24px 120px 36px 36px;
  }
  .post-auther {
    font-size: 18px;
  }
  .poster {
    font-size: 14px;
    text-overflow: clip;
  }
  .show-all {
    margin-top: 8px;
    font-size: 14px;
  }
}

.text-ellipsis-wrapper {
  display: flex;
  margin: 6px 0 20px 0;
  overflow: hidden;
  font-size: 14px;
  line-height: 35px;
}
.post-text {
  position: relative;
  overflow: hidden;
  line-height: 1.2;
  text-align: justify;
  text-overflow: ellipsis;
  word-break: break-all;
  &::before {
    float: right;
    height: calc(100% - 35px);
    content: '';
  }
}
.expand-btn {
  position: relative;
  float: right;
  clear: both;
  margin-left: 10px;
  font-size: 14px;
  padding: 0 4px;
  line-height: 35px;
  border-radius: 4px;
  cursor: pointer;
  z-index: 10;

  &::before {
    position: absolute;
    left: 1px;
    transform: translateX(-100%);
    content: '...';
  }
}
</style>

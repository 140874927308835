import { ref } from 'vue'
export function useConvert () {
  const onloading = ref(true)
  const textToTagFormat = text => {
    const arry = text.split(' ')
    if (arry[0].substr(0, 1) === '@') {
      text = text.replace(arry[0], `<a href="${arry[0]}">${arry[0]}</a>`)
    }
    return text
  }
  const toCurrency = number => {
    let prices = [number]
    return prices.toLocaleString('zh-TW', {
      style: 'currency',
      currency: 'TWD',
      minimumFractionDigits: 0
    })
  }

  const payIdToString = number => {
    let payString = ''
    switch (number) {
      case 3: // 贊助
        payString = 'post_donate'
        break
      case 4: // 訂閱
        payString = 'program'
        break
      case 5: // 促銷
        payString = 'promotions'
        break
      case 6: // 訂閱綑綁
        payString = 'subscription_bundle'
        break
      case 7: // 解鎖文
        payString = 'msg_donate'
        break
      case 8: // 籌資目標
        payString = 'post_target'
        break
      default:
        break
    }
    return payString
  }
  return {
    textToTagFormat,
    onloading,
    toCurrency,
    payIdToString
  }
}
export default useConvert
